<template>
  <Dialog
    v-model:visible="visible"
    modal
    :draggable="false"
    :style="{ width: '70vw' }"
    :contentStyle="{ height: '100%' }"
    :position="modalPosition"
    @hide="createCampaignForm = false"
  >
    <template #header>
      <p class="mx-auto text-lg text-neutral-high-medium">
        Unidade escolhida: <strong>{{ props.selectedWl.title }} </strong>
      </p>
    </template>
    <div>
      <div v-if="!isLogged()">
        <p class="mb-4 text-center text-lg">Acesse com seu e-mail para identificarmos sua conta</p>
        <div class="mx-auto flex w-4/5 flex-col gap-3">
          <div class="mob-class-12 p-input-icon-right flex flex-col">
            <label class="mt-2" for="email">E-mail</label>
            <p class="p-input-icon-right w-full">
              <InputText
                id="email"
                v-model="form.email"
                autocomplete="off"
                size="small"
                name="email"
                type="email"
                placeholder="ex.: joao@gmail.com"
                class="w-full"
                :class="{
                  'p-invalid': !emailIsValid && form.email.length,
                }"
                @update:model-value="emailValidation"
                @blur="handleEmail"
              />
              <i class="uil uil-envelope" />
            </p>
            <small v-if="!emailIsValid && form.email.length" id="email-help" class="mt-2"
              >Insira um e-mail válido.</small
            >
          </div>
          <Transition name="show-password" mode="out-in">
            <div v-if="alreadyUser" class="mob-class-12">
              <label class="mt-2" for="password">Senha</label>
              <Password
                id="password"
                v-model="form.password"
                toggleMask
                placeholder="Senha"
                name="password"
                class="p-component p-inputtext-sm w-full"
                :feedback="false"
                @blur="dispatchLogin()"
              />
            </div>
          </Transition>
        </div>
      </div>
      <p class="mb-4 mt-8 text-center text-lg">Escolha quando pretende iniciar e o prazo de veiculação</p>
      <div class="mx-auto w-4/5">
        <div class="relative grid grid-cols-2 gap-6 pb-5">
          <InputBox
            v-model="startDate"
            name="date_start"
            label="Início"
            type="date"
            class="w-full"
            icon="uil-calender"
            iconColor="#000"
            placeholder="Quando você quer iniciar?*"
            :modelValue="startDate"
            autofocus
          />
          <div class="flex w-full flex-col justify-between">
            <strong>Prazo</strong>
            <Dropdown
              v-model="duration.data"
              optionLabel="name"
              :options="days"
              placeholder="Por quantos dias deseja anunciar?*"
              class="w-full py-[.31rem]"
            />
          </div>
        </div>
        <!-- <DiscountCoupon :noBorder="true" /> -->
        <div class="align-items-center flex flex-col">
          <div>
            <Checkbox v-model="storekeeper" :inputId="`storekeeper`" :value="true" :binary="true" />
            <label :for="`storekeeper`" class="ml-2"> Confirmo que sou lojista do {{ props.selectedWl.title }} </label>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button label="cancelar" outlined class="!text-primary-default" @click="visible = false" />
      <Button
        label="continuar"
        icon="uil-angle-right !font-thin !text-white"
        iconPos="right"
        :disabled="!canCreateCampaign"
        @click="create()"
      />
    </template>
  </Dialog>
</template>

<script setup>
import DiscountCoupon from "@components/DiscountCoupon.vue";
import { CampaignDurationAsList } from "@enums/OrderEnum";
import { activeCampaign, activeCondominium, userFormulario } from "@store/store.ts";
import Cookies from "js-cookie";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { onMounted, reactive, ref, watch } from "vue";

import InputBox from "@/components/InputBox.vue";
import { usePixelRatio } from "@/hooks";
import { rotaResultados } from "@/routes";
import { login } from "@/services/login";
import UserFormBuilder from "@/services/ResultsService/UserFormBuilder";
import { useCampaignStore } from "@/stores/CampaignStore";
import { useUserStore } from "@/stores/UserStore";
import validateEmail from "@/utils/validateEmail";

const UserStore = useUserStore();
const CampaignStore = useCampaignStore();

const { email, token, google_auth } = storeToRefs(UserStore);
const { pixelRatio } = usePixelRatio();

const toast = useToast;
const props = defineProps({
  selectedWl: {
    type: Object,
  },
  name: {
    type: String,
  },
  slug: {
    type: String,
    default: "",
  },
});

const form = reactive({
  email: "",
  password: "",
});

const visible = ref(false);
const createCampaignForm = ref(false);
const emailIsValid = ref(false);
const canCreateCampaign = ref(false);
const modalPosition = ref("center");

const days = CampaignDurationAsList;

const emailValidation = (event) => {
  emailIsValid.value = validateEmail(event);
};

const handleEmail = async (e) => {
  const email = form.email;

  if (!email || !validateEmail(email)) {
    alreadyUser.value = false;
    return;
  }

  userFormulario.set({
    email: form.email,
  });

  Cookies.set("hasEmail", true, { expires: 7 });

  // sendHubspotLead(email)
  await verifiesUser(email);
};
async function dispatchLogin() {
  const { email, password } = form;

  UserStore.login({ email, password });
}

const alreadyUser = ref(false);

const duration = reactive({ data: {} });
const enderecoId = reactive({ data: {} });
const whiteLabelAddress = reactive({ data: {} });

const startDate = ref(null);
const storekeeper = ref(true);

const verifiesUser = async (email) => {
  return await fetch(`/apis/Core/verifies-user?email=${email}`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((userVerifies) => {
      alreadyUser.value = userVerifies.data.user_exists;
      return userVerifies;
    });
};

const create = async () => {
  const email = UserStore.google_auth.email ?? UserStore.email ?? "";

  const formBuilder = new UserFormBuilder(userFormulario.get());
  formBuilder
    .setEmail(email)
    .setDate(startDate.value)
    .setDuration(duration.data)
    .setAddressName(whiteLabelAddress.data.address)
    .setAddressId(whiteLabelAddress.data.addressId)
    .setAddressCoordinate()
    .setCondominiumAddressName(whiteLabelAddress.data.address)
    .setCondominiumAddressId(whiteLabelAddress.data.addressId)
    .setWhiteLabelName(props.slug);

  if (storekeeper.value) {
    activeCondominium.set(props.selectedWl.condominoId.toString());
    formBuilder.setCondominiumId(props.selectedWl.condominoId.toString());
  } else {
    activeCondominium.set("");
    formBuilder.setCondominiumId("");
  }

  const formData = formBuilder.getForm();
  userFormulario.set(formData);
  CampaignStore.removeCampaign();
  document.location.href = rotaResultados.url;
};

const isLogged = () => {
  return (UserStore.google_auth.email || UserStore.email) && UserStore.token;
};

watch(
  () => props.selectedWl,
  (newWl) => {
    if (newWl && Object.keys(newWl).length != 0) {
      visible.value = true;
      enderecoId.data = newWl.idLatLong;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => [form.email, form.password, duration.data, startDate.value],
  ([newEmail, newPassword, newDuration, newStartDate]) => {
    if (isLogged() || validateEmail(form.email)) {
      canCreateCampaign.value = Object.keys(newDuration).length > 0 && newStartDate.toString().length;
    }
  },
  {
    immediate: true,
  },
);

const getWhiteLabelAddress = async () => {
  await fetch(`/apis/Mapa/busca?address=${props.selectedWl.title} ${props.selectedWl.address}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((json) => {
      whiteLabelAddress.data.address = json[0].name;
      whiteLabelAddress.data.addressId = json[0].id;
    });
};

onMounted(() => {
  document.body.classList.add("wl");
  if (isLogged()) emailIsValid.value = true;

  // modalPosition.value = pixelRatio.value === 1.5 ? "bottom" : "center";
});

watch(visible, (newValue) => {
  if (visible.value) getWhiteLabelAddress();
});
</script>

<style lang="scss" scoped>
:deep(.p-dialog-header-icon) {
  border-radius: none;
  width: auto;
}

.show-password-enter-active,
.show-password-leave-active {
  transition: all 0.25s ease-out;
}

.show-password-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.show-password-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
